import React, { Component } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import devoxxkids1 from "../images/devoxx/galimg_16_view.jpg"
import devoxxkids2 from "../images/devoxx/robot.jpg"
import devoxxkids3 from "../images/devoxx/robot3.jpg"
// import geexMagazin1 from "../pdf/GeexReview.pdf"
// import geexMagazin2 from "../pdf/GeexReview-Craftsmanship.pdf"
// import geexMagazin3 from "../pdf/GeexReview-DevOps.pdf"
// import geexMagazin4 from "../pdf/GeexReview-uiux.pdf"
// import geexMagazin5 from "../pdf/GeexReview-Women-in-tech.pdf"
// import { dateFormat, ReadMore } from "../components/helpers"
import {
  Container,
  Section,
  SectionTitle,
  Banner,
  SubTitle,
  PrimaryButton,
  NewEdition,
} from "../styles/common"
// import Loader from "../components/loader"
import Layout from "../components/layout"
import {
  DevoxxText,
  DevoxxContainer,
  DevoxxImgs,
  DevoxxImg,
  CommunitySocialMedia,
  /*Xmeetups,
  Xmeetup,
  XmeetupDetails,
  XmeetupDate,*/
} from "../styles/community"
import SEO from "../components/seo"
import devoxx2019 from "../images/devoxx2019.jpg"

import poster from "../images/magazin/cover4.jpg"

// import fb_config from "../config/fb.json"

class CommunityPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      xmeetupsData: [],
      fetching: true,
    }
  }
  // componentDidMount() {
  //   window.FB.api(
  //     `/${
  //       fb_config.page_id
  //     }/events?fields=cover,description,name,start_time,id,link&limit=${
  //       fb_config.limit
  //     }`,
  //     "get",
  //     { access_token: fb_config.token },
  //     response => {
  //       console.log(response.data)
  //       this.setState({
  //         xmeetupsData: response.data || [],
  //         fetching: false,
  //       })
  //     }
  //   )
  // }
  render() {
    // const { fetching, xmeetupsData } = this.state
    return (
      <Layout>
        <SEO
          title="xhub community | x-hub.io"
          keywords={[
            "xhub",
            "devoxx",
            "devoxxmorocco",
            "devoxx4kids",
            "morocco",
            "casablanca",
            "x-hub.io",
            "devc",
            "Application & Technologies",
            "Badr Elhouari",
            "Mohammed Aboullaite",
          ]}
        />
        <Banner community overlay>
          <Container flex>
            <ScrollAnimation
              style={{ width: "100%" }}
              duration={0.4}
              delay={0.5}
              animateOnce={true}
              animateIn="slideInLeft"
            >
              <SectionTitle color="white" transform="uppercase" align="center">
                We are community driven.
              </SectionTitle>
              <SubTitle color="white" align="center">
                We believe in our community that's why all our actions are meant
                to
                <br />
                improve it though our xMeetups and many more actions
              </SubTitle>
            </ScrollAnimation>
          </Container>
        </Banner>

        <Section bgcolor="white">
          <Container>
            <ScrollAnimation animateOnce={true} animateIn="slideInLeft">
              <SectionTitle
                align="left"
                transform="uppercase"
                space="30px 0 40px"
                lightChild
                color="black"
              >
                GEE<div>XREVIEW</div>
              </SectionTitle>
            </ScrollAnimation>

            <DevoxxContainer>
              <DevoxxText style={{ paddingTop: "0px" }}>
                <ScrollAnimation animateOnce={true} animateIn="slideInUp">
                  <p>
                    <span>Geex Review</span> is a quarterly digital magazine
                    made by developers for developers. The main goal of this
                    digital magazine is to spread coding culture & sharing the
                    latest technology subjects with the community of developers
                    & IT passionate, you can find experts interviews, Technology
                    review, tips & tricks etc. each edition will have a specific
                    topic. Our magazine is an open collaborative magazine, so if
                    you have anything you see relevant to share with the
                    developer community, feel free to send us your suggestions
                    on the following address{" "}
                    <a
                      href="mailto:geex.review@x-hub.io"
                      //target="_blank"
                      // rel="noopener noreferrer"
                    >
                      geex.review@x-hub.io
                    </a>
                  </p>
                  {/* <CommunitySocialMedia>
                    <a
                      href="https://devoxx4kids.ma"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="icon-internet">
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                        <span className="path4" />
                      </span>
                    </a>
                    <a
                      href="https://www.flickr.com/photos/142552589@N08"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="icon-flickr-logo" />
                    </a>
                    <a
                      href="https://www.facebook.com/Devoxx4KidsMa"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="icon-facebook-circle" />
                    </a>
                  </CommunitySocialMedia> */}
                  {/* <PrimaryButton
                    style={{ marginRight: "10px" }}
                    space="20px 0 0 0"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={geexMagazin1}
                  >
                    Edition 1.0
                  </PrimaryButton>
                  <PrimaryButton
                    style={{ marginRight: "10px" }}
                    space="20px 0 0 0"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={geexMagazin2}
                  >
                    Edition 2.0
                  </PrimaryButton>
                  <PrimaryButton
                    style={{ marginRight: "10px"}}
                    space="20px 0 0 0"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={geexMagazin3}
                  >
                    Edition 3.0
                  </PrimaryButton>
                  <PrimaryButton
                    style={{ marginRight: "10px"}}
                    space="20px 0 0 0"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={geexMagazin4}
                  >
                    Edition 4.0
                  </PrimaryButton>
                  <NewEdition
                    // style={{ marginRight: "10px", backgroundColor: "#A45FA7", borderColor: "#A45FA7", color: "white" }}
                    background= "red"
                    space="20px 0 0 0"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={geexMagazin5}
                  >
                    New Edition
                  </NewEdition> */}
                </ScrollAnimation>
              </DevoxxText>

              <DevoxxImgs>
                <ScrollAnimation animateOnce={true} animateIn="fadeInUp">
                  {/* <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={geexMagazin5}
                  >
                    <DevoxxImg src={poster} full />
                  </a> */}
                  {/* <video
                    width="100%"
                    height="auto"
                    controls="false"
                    autoPlay
                    muted
                    poster={poster}
                  >
                    <source src={video} type="video/mp4" />
                  </video> */}
                </ScrollAnimation>
              </DevoxxImgs>

              
            </DevoxxContainer>
          </Container>
        </Section>

        <Section bgcolor="white">
          <Container>
            <ScrollAnimation animateOnce={true} animateIn="slideInRight">
              <SectionTitle
                align="right"
                transform="uppercase"
                space="30px 0 40px"
                lightChild
                color="black"
              >
                DEVOXX <div>MOROCCO</div>
              </SectionTitle>
            </ScrollAnimation>

            <DevoxxContainer>
              <DevoxxImgs>
                <DevoxxImg src={devoxx2019} full />
                <DevoxxImg
                  src={
                    "//farm5.staticflickr.com/4880/46136610882_4cb8171308_z.jpg"
                  }
                />
                <DevoxxImg src="//farm5.staticflickr.com/4869/46187229301_165c8c5d9f_n.jpg" />
              </DevoxxImgs>

              <DevoxxText style={{ paddingTop: "0px" }}>
                <ScrollAnimation animateOnce={true} animateIn="slideInUp">
                  <p>
                    <span>Devoxx Morocco</span> is the annual largest Developers
                    conference in the MEA region. it's + 200 workshops,
                    conferences & keynote centered on computer technologies,
                    from Mobile to Big Data, Programming languages & Enterprise
                    Solutions.
                  </p>
                  <PrimaryButton
                    space="20px 0 0"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://devoxx.ma"
                  >
                    see more details
                  </PrimaryButton>
                </ScrollAnimation>
              </DevoxxText>
            </DevoxxContainer>
          </Container>
        </Section>

        {/* <Section bgcolor="white">
          <Container>
            <ScrollAnimation animateOnce={true} animateIn="slideInLeft">
              <SectionTitle
                align="left"
                transform="uppercase"
                space="30px 0 40px"
                lightChild
                color="black"
              >
                <div>X</div>Meetups
              </SectionTitle>
            </ScrollAnimation>
            <Xmeetups>
              {fetching ? (
                <Fragment>
                  <Xmeetup>
                    <Loader />
                  </Xmeetup>
                  <Xmeetup>
                    <Loader />
                  </Xmeetup>
                  <Xmeetup>
                    <Loader />
                  </Xmeetup>
                  <Xmeetup>
                    <Loader />
                  </Xmeetup>
                </Fragment>
              ) : (
                xmeetupsData.map(item => {
                  const date = dateFormat(item.start_time)

                  return (
                    <Xmeetup key={item.id}>
                      <ScrollAnimation animateOnce={true} animateIn="slideInUp">
                        <img alt={item.name} src={item.cover.source} />
                        <XmeetupDetails>
                          <XmeetupDate>
                            <span className="day">{date.day}</span>
                            <span className="month">{date.month}</span>
                            <span className="year">{date.year}</span>
                          </XmeetupDate>
                          <h2>
                            <a
                              target="_blank"
                              href={`https://www.facebook.com/events/${item.id}`}
                              rel="noopener noreferrer"
                            >
                              {item.name}
                            </a>
                          </h2>
                          <ReadMore text={item.description} />
                        </XmeetupDetails>
                      </ScrollAnimation>
                    </Xmeetup>
                  )
                })
              )}
            </Xmeetups>
            <div style={{ textAlign: "center" }}>
              <PrimaryButton
                space="70px auto 0"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/pg/xhub.io/events/"
              >
                See more details
              </PrimaryButton>
            </div>
          </Container>
        </Section> */}

        <Section bgcolor="white">
          <Container>
            <ScrollAnimation animateOnce={true} animateIn="slideInLeft">
              <SectionTitle
                align="left"
                transform="uppercase"
                space="30px 0 40px"
                lightChild
                color="black"
              >
                DEVOXX<div>4Kids</div>
              </SectionTitle>
            </ScrollAnimation>

            <DevoxxContainer>
              <DevoxxText style={{ paddingTop: "0px" }}>
                <ScrollAnimation animateOnce={true} animateIn="slideInUp">
                  <p>
                    <span>Devoxx4Kids</span> is an international NGO whose
                    mission is to give the passion of Coding & Robotics to the
                    future generation ( Children from 6 to 14 years old),
                    through the organization of fun and educational workshops
                    that allow children to discover programming, Computer logic,
                    and Robotics while having fun!
                  </p>
                  <CommunitySocialMedia>
                    <a
                      href="https://devoxx4kids.ma"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="icon-internet">
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                        <span className="path4" />
                      </span>
                    </a>
                    <a
                      href="https://www.flickr.com/photos/142552589@N08"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="icon-flickr-logo" />
                    </a>
                    <a
                      href="https://www.facebook.com/Devoxx4KidsMa"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="icon-facebook-circle" />
                    </a>
                  </CommunitySocialMedia>
                </ScrollAnimation>
              </DevoxxText>

              <DevoxxImgs>
                <DevoxxImg src={devoxxkids1} alt="galimg 16 view" />
                <DevoxxImg src={devoxxkids2} alt="robot" />
                <DevoxxImg src={devoxxkids3} alt="robot" full />
              </DevoxxImgs>
            </DevoxxContainer>
          </Container>
        </Section>
      </Layout>
    )
  }
}

export default CommunityPage
